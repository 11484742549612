import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This past year has been filled with many exciting milestones for PayPerks, one more exciting that the next.  Nothing does a better job of putting those successes into context than this white paper, released today by the Federal Reserve Bank of Philadelphia's `}<a parentName="p" {...{
        "href": "http://www.phil.frb.org/consumer-credit-and-payments/payment-cards-center/"
      }}>{`Payments Cards Center`}</a>{`, about PayPerks.`}</p>
    <p>{`The genesis of this paper began in July 2011 when `}<a parentName="p" {...{
        "href": "http://www.phil.frb.org/consumer-credit-and-payments/payment-cards-center/specialists/herbst-murphy/"
      }}>{`Susan Herbst-Murphy`}</a>{`, the author of the piece, learned about PayPerks at a conference on the government’s use of prepaid cards. From there, she invited us to conduct a workshop at the Federal Reserve Bank of Philadelphia.`}</p>
    <p>{`Over the 2+ years since we first met Susan, PayPerks has grown from 2 to nearly 30 people, launched on numerous programs and touched hundreds of thousands of cardholders. The paper, "Driving Positive Behavior Change through Education and Motivation" `}<a parentName="p" {...{
        "href": "http://www.phil.frb.org/consumer-credit-and-payments/payment-cards-center/publications/discussion-papers/2013/D-2013-October-Payperks.pdf"
      }}>{`(link to download PDF)`}</a>{` provides a deep dive on what happened along that path.`}</p>
    <p>{`Here's a summary provided by the author:`}</p>
    <blockquote>
      <p parentName="blockquote">{`This paper discusses the fusion of financial literacy instruction and gamification as developed and delivered by start-up firm PayPerks. PayPerks’ initial emphasis has been on improving the understanding and use of prepaid cards among individuals with little prior experience using payment cards. PayPerks co-founders facilitated a Payment Cards Center workshop where they demonstrated their product and discussed the journey it took from raw idea to commercialization. Two significant new PayPerks partnerships, including one with the U.S. Treasury Department’s DirectExpress program, were also announced during this workshop. Along with describing a novel approach to consumer financial education, the PayPerks story is also a study in innovation and entrepreneurship.`}</p>
    </blockquote>
    <p><a parentName="p" {...{
        "href": "http://www.phil.frb.org/consumer-credit-and-payments/payment-cards-center/publications/discussion-papers/2013/D-2013-October-Payperks.pdf"
      }}>{`Download the full PDF`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      